import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "Home",
			component: () => import("./views/Home.vue")
		},
		{
			path: "/game",
			name: "Game",
			component: () => import("./views/Game/Index.vue"),
			children: [
				{
					path: "map",
					name: "Game_Map",
					component: () => import("./views/Game/Map.vue")
				},
				{
					path: "form",
					name: "Game_Form",
					component: () => import("./views/Game/Form.vue")
				},
				{
					path: "stage/:stage",
					name: "Game_Stage",
					component: () => import("./views/Game/Stage.vue")
				},
				{
					path: "result/:stage",
					name: "Game_Result",
					component: () => import("./views/Game/Result.vue")
				},
			],
		},
		{
			path: "/report",
			name: "Report",
			component: () => import("./views/Report.vue")
		},
		{
			path: "*",
			redirect: { name: "Home" },
		},
	]
})

export default router
