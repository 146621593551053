import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: {},
		progress: {},
		score: undefined,
	},
	mutations: {
		setUser(state, _user) {
			state.user = _user
		},
		setProgress(state, _progress) {
			state.progress = _progress
		},
		setScore(state, _score) {
			state.score = _score
		},
		updateProgress(state, _payload) {
			state.progress[`stage${_payload.stage}`] = _payload.score
		}
	},
	actions: {
	},
	modules: {
	}
})
