import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router.js"
import store from "./store"

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
	config: { id: "UA-207367163-1" },
}, router);


// CSS
require("./scss/reset.scss");
require("./scss/rwd.scss");
require("./scss/align.scss");

Vue.config.productionTip = false

new Vue({
	router,
	store,
	data() {
		return {
			rwd: 1,
			navbarHeight: 60
		}
	},
	methods: {
		initRWD() {
			/**
			 * desktop: 1 [>768]
			 * pad: 2 [768~426]
			 * mobile: 3 [<=425]
			 */
			this.rwd = window.innerWidth > 768 ? 1 : window.innerWidth <= 425 ? 3 : 2;
		},
	},
	created() {
		this.initRWD();
		window.addEventListener("resize", this.initRWD);
	},
	render: function (h) { return h(App) }
}).$mount("#app")
